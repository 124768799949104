
  .D3Window {
    font-family: sans-serif;
    background-color: #0e006b;
    border: 1px solid white;
  }
  
  .axisX text {
    text-anchor: start;
  }
  
  .baseGridLine line {
    stroke: rgba(255, 255, 255, 0.5);
  }
  
  .tooltip text {
    font-size: 12px;
    fill: #ffffff;
  }
  
  .tooltipLine {
    stroke: #ff007a;
    stroke-width: 1px;
  }
  
  .tooltipContent .contentTitle {
    font-weight: bold;
  }
  
  .tooltipLinePoint {
    fill: #ff007a;
  }
  
  .legendContainer {
    display: flex;
    justify-content: center;
    color: #ffffff;
  }
  
  .checkbox {
    margin: 10px;
  }
  